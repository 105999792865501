// src/components/rows/rows.js

import React from "react"
import { overwriteTailwindClassNames as overwriteTw } from "../../utils/helper"

const fullRowClasses =
  "w-full pb-12 mx-auto md:mx-1/24 sm:w-10/12" 
  export const FullRow = ({ className = "", children }) => (
    <div className={overwriteTw(fullRowClasses, className)}>
      {children}
    </div>
  )


  // decorater FullRow
const bannerHeadlineRowClasses = "pt-20 lg:pt-28 "
  export const BannerHeadlineRow = ({ className = "", children }) => (
    //  is else in section part, only in banner
    <div className="max-w-screen-lg mx-auto ">
      <FullRow
        className={overwriteTw(bannerHeadlineRowClasses, className)}
        children={children}
      />
    </div>
  )

  // const IndentedRowClasses = "w-10/12 mx-auto sm:w-8/12"
  const IndentedRowClasses = "w-full  mx-auto ssm:w-10/12 sm:w-8/12"
  export const IndentedRow = ({ className = "", children }) => (
    <div className={overwriteTw(IndentedRowClasses, className)}>
      {children}
    </div>
  )