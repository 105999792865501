// src/components/buttons/buttons.js

import React from "react"
import { Link } from "gatsby"
import { ReactIcon } from "../icons"

const ContextValueRed = {
  color: "#FF645D",
  size: "22px",
  style: { verticalAlign: "middle" },
}

const ContextValueWhite = {
  color: "#ffffff",
  size: "22px",
  style: { verticalAlign: "middle" },
}

const LinkButtonDefault = ({
  url,
  className = "",
  label,
  contextValue = {},
  iconId,
}) => (
  <div
    className={`${className} group inline-block buttonSM sm:text-buttonSM md:text-buttonMD lg:text-buttonLG px-4 font-bold `}
  >
    <Link to={url}>
      <div className="flex items-center">
        <span className="pr-2 ">{label}</span>
        {iconId && (
          <span className="group-hover:animate-pulseScale">
            <ReactIcon id={iconId} contextValue={contextValue} />
          </span>
        )}
      </div>
    </Link>
  </div>
)

export const LinkButtonSecondary = (props) => (
  <LinkButtonDefault
    {...props}
    className={`${
      props.className || ""
    } border-2 border-primary-500  text-primary-500`}
    contextValue={ContextValueRed}
  />
)

export const LinkButtonPrimary = (props) => (
  <LinkButtonDefault
    {...props}
    className={`${
      props.className || ""
    } border-2 border-primary-500 bg-primary-500   text-white`}
    contextValue={ContextValueWhite}
  />
)

export const LinkButton = (props) => (
  <LinkButtonDefault
    {...props}
    className={`${
      props.className || ""
    }  text-primary-500`}
    contextValue={ContextValueRed}
  />
)


// export const LinkIcon = (props) => (
//   <LinkButtonDefault
//     {...props}
//     className={`${props.className || ""}`}
//     contextValue={ContextValueRed}
//   />
// )

// export const LinkButton__white = (props) => (
//   <LinkButtonDefault
//     {...props}
//     className={`${
//       props.className || ""
//     } py-2 border-white bg-warm-gray-800 text-white`}
//     contextValue={{ ...ContextValueRed, ...{ color: "white" } }}
//   />
// )
