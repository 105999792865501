// src/components/headline/headline.js

import React from "react"
import { overwriteTailwindClassNames as overwriteTw } from "../../utils/helper"

const headlineClasses = "text-primary-500" 
const classNamesSubHeadline =
  "block pt-3px lg:pt-2 text-4xl sm:text-4xlSM md:text-4xlMD lg:text-4xlLG text-warm-gray-900"

const getHTML = ( headline, subheadline ) =>  `${headline} <span class="${classNamesSubHeadline}">${subheadline}</span>`

export const HeadlineH1 = ({className='', headline, subheadline}) => {
  
  return (
    <h1
      className={overwriteTw(className, headlineClasses)}
      dangerouslySetInnerHTML={{ __html: getHTML(headline, subheadline) }}
    ></h1>
  )}

export const HeadlineH4 = ({ className = "", headline, subheadline }) => (
  <h4
    className={overwriteTw(className, headlineClasses)}
    dangerouslySetInnerHTML={{ __html: getHTML(headline, subheadline) }}
  ></h4>
)