import React from "react"
import PropTypes from "prop-types"
import { IconContext } from "react-icons"
import {
  FaRegMap,
  FaProjectDiagram,
  FaLinkedin,
  FaChevronRight,
  FaChevronLeft,
  // FaRegCalendarCheck
} from "react-icons/fa"

import {
  BiCalendarCheck
} from "react-icons/bi"

import {
  HiArrowNarrowRight
} from "react-icons/Hi"
import {
  MdTune,
  MdChangeHistory,
  MdSearch,
  MdReplay,
  MdNearMe,
  MdCached,
   MdFace,
  MdLightbulbOutline,
  MdCreate,
  MdWeb,
} from "react-icons/md"

import {failIfNotExist} from "../../utils/helper"

// Dynamic Components - here icons from react-icons
// @see https://stackoverflow.com/questions/29875869/react-jsx-dynamic-component-name
const iconComponents = {
  regMap: FaRegMap,
  projectDiagram: FaProjectDiagram,
  linkedin: FaLinkedin,
  calenderCheck: BiCalendarCheck,
  chevronRight: FaChevronRight,
  chevonRonLeft: FaChevronLeft,
  arrowRight: HiArrowNarrowRight,
  changeHistory: MdChangeHistory,
  tune: MdTune,
  search: MdSearch,
  replay: MdReplay,
  nearMe: MdNearMe,
  cached: MdCached,
  lightbulb: MdLightbulbOutline,
  create: MdCreate,
  face:  MdFace,
  web: MdWeb,
}

export const LINKEDIN_ICON = "linkedin"
export const ARROW_RIGHT_ICON = "arrowRight"
export const CALENDER_CHECK = "calenderCheck"

export const ReactIcon = ({ id, contextValue = {} }) => {
         const Icon = iconComponents[id]

         failIfNotExist(
           Icon,
           `in ComponentIcon: Did not find an apropriate Icon in react-icons ${id}`
         )
         return (
           <IconContext.Provider value={contextValue}>
             <Icon />
           </IconContext.Provider>
         )
       }

ReactIcon.propTypes = {
  id: PropTypes.string.isRequired,
}