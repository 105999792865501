// src/pages/404.js

import React from "react"

import HeaderBar from "../components/headerBar"
import Footer from "../components/footer"
import { BrightSection } from "../components/sections"
import { FullRow } from "../components/rows"
import { HeadlineH1 } from "../components/headline"
import { LinkButtonPrimary } from "../components/buttons"

const headline = "Hier ist etwas schief gelaufen!"
const subheadline = "Diese existiert nicht auf dem Server. (404 Error)."
export default () => (
  <div className="flex flex-col h-screen">
    <HeaderBar />
    <BrightSection>
      <FullRow className="flex-1 pt-36">
        <HeadlineH1
          headline={headline}
          subheadline={subheadline}
          className="pb-20"
        />

        <LinkButtonPrimary url="/" label="zurück zur Homepage Pepe Jürgens" />
      </FullRow>
    </BrightSection>
    <Footer />
  </div>
)